<template>
    <b-card title="Online Users">
  
        <!-- search input -->
        <div class="custom-search d-flex">
            <b-form-group class="justify-content-end">
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </div>
            </b-form-group>
            <!-- <div class="search-gap">
                <b-form-group class="justify-content-end"> 
                    <div class="d-flex align-items-center">
                        <v-select
                            class="dropdown-size"
                            placeholder="Select a Status"
                            v-model="profile_status"
                            :options="profile_statuses"
                            :reduce="name=>name.value"
                            label="name"
                            @input="getFilterByStatus()"
                        />
                    </div>
                </b-form-group>
            </div> -->
            <!-- <b-form-group class="justify-content-end">
                <div class="d-flex align-items-center">
                    <v-select
                        class="dropdown-size"
                        placeholder="Select a Gender"
                        v-model="gender"
                        :options="genders"
                        :reduce="name=>name.value"
                        label="name"
                        @input="getFilterByGender()"
                    />
                </div>
            </b-form-group> -->
        </div>
    
        <!-- table -->
        <div v-if="!userLoading && !userError">
            <vue-good-table
                :columns="columns"
                :rows="rows"
                :rtl="direction"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm 
                }"
                :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
                styleClass="vgt-table striped condensed no-outline"
            >
        
                <template
                    slot="table-row"
                    slot-scope="props"
                >
        
                    <!-- Column: Name -->
                    <span
                        v-if="props.column.field === 'user_object.first_name'"
                        class="text-nowrap"
                    >
                        <router-link :to="{ path: '/admin/user/profile/' + props.row.personal_info.profile_id }">
                            <span class="text-nowrap">{{ props.row.user_object.first_name }} {{ props.row.user_object.last_name }}</span>
                        </router-link>
                    </span>
                    
                    <!-- Column: Index -->
                    <span v-else-if="props.column.field === 'index'">
                        <span>{{props.row.originalIndex+1}}</span>
                    </span>

                    <!-- Column: Free/Premium -->
                    <span v-else-if="props.column.field === 'premium_status'">
                        <span v-if="props.row.personal_info.premium_status === 'premium'"><b-badge pill variant="light-success">Premium</b-badge></span>
                        <span v-else><b-badge pill variant="light-info">Regular</b-badge></span>
                    </span>

                    <!-- Column: Status -->
                    <span v-else-if="props.column.field === 'profile_status'">
                        <span>
                            <span v-if="props.row.personal_info.profile_status == 'active'"><b-badge pill variant="light-success">{{props.row.personal_info.profile_status}}</b-badge></span>
                            <span v-else> <b-badge pill variant="light-danger">{{props.row.personal_info.profile_status}}</b-badge></span>
                        </span>
                    </span>
            
                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
        
                <!-- pagination -->
                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['3','5','10','50','100']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>

        <div v-else class="loading-user">
            <img src="../../../../assets/images/logo/logo.png" alt="">
            <div class="load-6">
                <div class="letter-holder">
                    <div class="l-1 letter">P</div>
                    <div class="l-2 letter">l</div>
                    <div class="l-3 letter">e</div>
                    <div class="l-4 letter">a</div>
                    <div class="l-5 letter">s</div>
                    <div class="l-6 letter mr-1">e</div>
                    <div class="l-7 letter">W</div>
                    <div class="l-8 letter">a</div>
                    <div class="l-9 letter">i</div>
                    <div class="l-10 letter">t</div>
                    <div class="l-11 letter">.</div>
                    <div class="l-12 letter">.</div>
                    <div class="l-13 letter">.</div>
                </div>
            </div>
        </div>
    </b-card>
</template>
  
  <script>
  
import {
BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
BModal,VBModal,BCardText, BFormTextarea
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        vSelect,
        BCardText,
        BFormTextarea
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    data() {
        return {
            nameState: null,
            addNewMode:false,
            updateStatusMode: false,
            profile_status: null,
            gender: null,
            popupActive:true,
            status: {
                id: "",
                status: ""
            },
            modify:{
                id: null,
                name: null,
                district: null,
                country: null
            },
            country_id:null,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: '#',
                    field: 'index',
                    type:'number',
                    tdClass: 'text-center ',
                    thClass:'text-center'
                },
                {
                    label: 'Name',
                    field: 'user_object.first_name',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Phone',
                    field: 'personal_info.phone_number',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Email',
                    field: 'user_object.email',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Profile ID',
                    field: 'personal_info.profile_id',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Regular/Premium',
                    field: 'premium_status',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Status',
                    field: 'profile_status',
                    tdClass: 'text-center',
                    thClass:'text-center'
                }
            ],
            showAdd:true,
            showEdit:false,
            showDelete:false,
            rows: [],
            searchTerm: '',
            statuses: [ 
                {
                    name: 'Checked',
                    value: 'checked'
                },
                {
                    name: 'Incompatible',
                    value: 'incompatible'
                },
                {
                    name: 'Delete',
                    value: 'deleted'
                }
            ],
            profile_statuses: [
                {
                    name: 'Free',
                    value: 'regular'
                },
                {
                    name: 'Premium',
                    value: 'premium'
                }
            ],
            page: 7,
            feedback: null,
            genders: [
                {
                    name: 'Male',
                    value: 'male'
                },
                {
                    name: 'Female',
                    value: 'female'
                }
            ],
            pages: [
                {
                    name: 'Phone Number Verification',
                    value: 1
                },
                {
                    name: 'Education & Profession',
                    value: 2
                },
                {
                    name: 'Present & Permanent Address',
                    value: 3
                },
                {
                    name: 'Family Information',
                    value: 4
                },
                {
                    name: 'Profile Pictures',
                    value: 5
                },
                {
                    name: 'NID / Passport',
                    value: 6
                },
                {
                    name: 'Nothing to update',
                    value: 8
                }
            ]
        }
    },

    computed: {
        ...mapState('users', ["users","userError","userLoading"]),
    
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },

    watch: {
        users(newValue, oldValue) {
            this.rows=this.users
        },

        userError(newValue, oldValue) {},

        userLoading(newValue, oldValue) {}
    },

    created() {
        this.$store.dispatch('users/fetchOnlineUser')
        this.rows = this.users
        let permissions=JSON.parse(localStorage.getItem('permissions'))
        //   this.showAdd=permissions.includes("add_area")
        //   this.showEdit=permissions.includes("change_area")
        //   this.showDelete=permissions.includes("delete_area")
        if(this.showEdit||this.showDelete){
            this.columns.push({
                label: 'Action',
                field: 'action',
                tdClass: 'text-center',
                thClass:'text-center',
                sortable: false,
            })
        }
    }
}
</script>
  
<style lang="scss" >
.no-outline{
    border:none !important;
}

.f20{
    font-size: 8px !important;
}

.vs__dropdown-menu {
  height: 200px;
}

.search-gap {
    margin-left: 20px;
    margin-right: 20px;
}

.dropdown-size {
    width: 250px;
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>